/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Faq from "pages/Faq";
import TherapistProgram from "pages/TherapistProgram";
import FindATherapist from "pages/FindATherapist";
import TherapistBioEditable from "pages/TherapistBioEditable";
import Blog from "pages/Blog";
import AboutUs from "pages/AboutUs";
import TherapyHub from "pages/TherapyHub";
import AuthPage from "pages/Auth";
import ShopPage from "pages/Shop";

const routes = [
  {
    name: "About Us",
    route: "/about-us",
    component: <AboutUs />,
    key: "about-us",
  },

  {
    name: "Our Packages",
    collapse: [
      {
        name: "Ketamine Packages",
        route: "/ketamine-therapy/packages",
        component: <ShopPage />,
        key: "our-packages",
      },
    ],
  },
  {
    name: "Our Services",
    collapse: [
      {
        name: "Ketamine Therapy",
        route: "/ketamine-therapy",
        // component: <KetamineTherapyHub />,
        component: <TherapyHub />,
        key: "ketamine-therapy",
      },
      {
        name: "Psilocybin Therapy",
        route: "/psilocybin-therapy",
        component: <TherapyHub />,
        key: "psilocybin-therapy",
      },
      // {
      //   name: "Traditional Therapy",
      //   route: "/traditional-therapy",
      //   component: <TherapyHub />,
      //   key: "traditional-therapy",
      // },
    ],
  },
  {
    name: "For Patients",
    collapse: [
      {
        name: "Find A Therapist",
        route: "/partner-providers",
        component: <FindATherapist />,
        key: "find-a-ketamine-therapist",
      },
      {
        name: "Account Order Information",
        route:
          process.env.REACT_APP_ENV === "production"
            ? process.env.REACT_APP_LIVE_STRIPE_CUSTOMER_PORTAL_PATH
            : process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_PATH,
        key: "order-info",
      },
    ],
  },
  {
    name: "For Therapists",
    collapse: [
      {
        name: "Our Therapist Referral Program",
        route: "/partners",
        component: <TherapistProgram />,
        key: "partners",
      },
      {
        name: "Therapist Profile",
        route: "/therapist-bio-editable",
        component: <TherapistBioEditable />,
        key: "therapist-program",
        protected: true,
        requiredRole: "partnered-therapist",
      },
      {
        name: "Partner Education Program",
        route: "https://partners.almadelic.com/therapist-education-get-started",
        key: "Education Program",
        protected: true,
        requiredRole: "partnered-therapist",
      },
      {
        name: "Therapist Profile Sign In",
        route: "/therapist-auth/sign-in",
        component: <AuthPage />,
        key: "auth-page",
      },
    ],
  },
  {
    name: "FAQ's",
    collapse: [
      {
        name: "Search FAQ's",
        route: "/faq",
        component: <Faq />,
        key: "faq",
      },
    ],
  },
  {
    name: "Blog",
    route: "/blog",
    component: <Blog />,
    key: "blog",
  },
];

export default routes;
