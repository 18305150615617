import styled from "@emotion/styled";
import {Grid} from '@mui/material';
import MKBox from "components/MKBox";
// export const StyledGridContainer = styled(Grid)(({theme}) => ({
//     flexDirection: "row",
//     justifyContent: "center",
//     marginTop: "4rem",
//     '&::before':{
//         filter: "blur(10px)",
//         backgroundImage: `url("https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/almadelicButterFlyTransparent.webp")`,
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//         backgroundSize: "contain",
//     }
    
// }));
export const StyledGridContainer = styled(Grid)(({ theme }) => ({
    position: "relative",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "4rem",
    '&::before': {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        filter: "blur(5px)",
        // backgroundImage: `url("https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/almadelicButterFlyTransparent.webp")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        zIndex: -1,
    }
}));

export const StyledItemBox = styled(MKBox)(({theme}) => ({
    border: `${theme.palette.accent.main} 1px solid`,
    borderRadius: "1rem",
    padding: "1rem",
    margin: "2rem 1rem",
    display: "flex",
    justifyContent: "center",
    boxShadow: `0 0 20px 0 ${theme.palette.accent.main}`,
}));