import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import HeaderContainer from "styles/components/headerContainer";
// Styled Components
import { StyledHeroImage, StyledHeroImageGridItem, StyledHeroSectionContainer, StyledHeroTextGridItem } from "./index.styles";
import { StyledGridContainer, StyledButtonContainer } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { ghlLinkFreeConsultation } from "utils/helpers/ghlFunnelLinks";
import withSectionAnimation from "styles/animations/components/withSectionAnimation";
import { fadeInVariantsTwo } from "styles/animations/sectionFadeIn.animations";
import { fadeInVariants } from "styles/animations/sectionFadeIn.animations";

const AnimatedStyledHeroSectionContainer = withSectionAnimation(StyledHeroSectionContainer, fadeInVariants);

const Hero = () => {
  const theme = useTheme();
  const { isMd, isLg } = useMediaQueries();

  return (
    <AnimatedStyledHeroSectionContainer component="section" id="hero-section">
      <StyledGridContainer container justifyContent={"space-between"}>
        <StyledHeroTextGridItem item order={isMd ? "2" : "2"} xs={12} md={8} lg={6}>
          <HeaderContainer
            title="Psychedelic Therapy Portal"
            subtitle="Begin Your Journey Today!"
            textColor={theme.palette.primary.main}
            highlightColor={theme.palette.accent.main}
            element="h1"
            variant="h1"
            cssProps={{ margin: "0rem 0rem 1rem 0rem !important" }}
          />

          <MKTypography
            component="p"
            variant={isLg ? "body1" : "body2"}
            sx={{ marginTop: "1rem", ".accent-highlight": { color: theme.palette.accent.main, fontWeight: "bold" } }}
          >
            Discover the potential of <span className="accent-highlight">At-Home Ketamine Therapy</span> from the safety & comfort of your home.
            Easily connect with our network of <span className="accent-highlight">Traditional Therapy Providers</span> for ongoing support. And,
            coming soon, experience new transformative breakthroughs with
            <span className="accent-highlight"> Psilocybin-Assisted Therapy</span>.
          </MKTypography>
          <StyledButtonContainer>
            <MKButton
              component={Link}
              to={ghlLinkFreeConsultation}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="secondary"
              size="large"
              aria-label="Schedule your consultation today"
              fullWidth={isMd ? false : true}
            >
              Schedule Your consultation today
            </MKButton>
          </StyledButtonContainer>
        </StyledHeroTextGridItem>
        <StyledHeroImageGridItem item order={isMd ? "1" : "1"} xs={12} md={1} lg={5}>
          <StyledHeroImage
            component={"img"}
            height={1}
            width={1}
            src={"https://d3a0rs3velfrz.cloudfront.net/home/hero/HeroImgSm.webp"}
            alt="Almadelic Home Page Image - an colorful abstract portrait of a womans face depicted as a tree."
          />
        </StyledHeroImageGridItem>
      </StyledGridContainer>
    </AnimatedStyledHeroSectionContainer>
  );
};
export default Hero;
