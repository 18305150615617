import React from 'react';
import {Grid} from "@mui/material";
import {FourSquareGridContainer} from "./index.styles";
import useMediaQueries from 'utils/mediaQueries.utils';
// //////////////////////////////////////////////////
// pass in 4 grid items, divider color, and image url
// //////////////////////////////////////////////////

const FourSquareBasicComponent = (children) => {
    const {isLg} = useMediaQueries();
    const cards = [children.component1, children.component2, children.component3, children.component4];

    

    const gridSection = (item) => {

        let borderStyle;
        switch(cards.indexOf(item)){
            case 0:
                borderStyle = {borderRight: `2px ${children.dividerType ? children.dividerType : "solid"} ${children.dividerColor}`, borderBottom: `2px ${children.dividerType ? children.dividerType : "solid"} ${children.dividerColor}`}
                break;
            case 1:
                borderStyle = {borderBottom: `2px ${children.dividerType ? children.dividerType : "solid"} ${children.dividerColor}`}
                break;
            case 2:
                borderStyle = {borderRight: `2px ${children.dividerType ? children.dividerType : "solid"} ${children.dividerColor}`}
                break;
            case 3:
                borderStyle = {}
                break;
            default:
                borderStyle = {}
        }

        if(!isLg && cards.indexOf(item) !== 3){
            borderStyle = {borderBottom: `2px ${children.dividerType ? children.dividerType : "solid"} ${children.dividerColor}`}
        }


        return(
            <Grid item xs={12} lg={6} sx={{padding: "1rem", ...borderStyle}}>
                {item}
            </Grid>
        )
    }



    return(
        <FourSquareGridContainer container isLg={isLg}  columnGap={0} rowGap={0} sx={{border: "3px solid white", borderRadius: "3rem", ...children.cssProps}}>
                {cards.map((item, index) => {
                    return gridSection(item)
                })}
        </FourSquareGridContainer>
    )
}


export default FourSquareBasicComponent;