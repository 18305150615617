/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import theme from "assets/theme";
// Components
import { Grid, List, ListItem } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import HeaderContainer from "styles/components/headerContainer";
// Styled Components
import { StyledButtonContainer } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import { StyledSectionContainerColorWrapper } from "styles/components/SectionContainer/index.styles";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import { ImageContainer } from "styles/components/imageContainer";
import ListComponent from "components/CBList";
import { StyledSectionContainer } from "styles/components";
import CBDivider from "components/CBDivider";
import ourServicesBulletPointData from "../OurServices/ourServicesBulletPoint.data.json";
import MKBox from "components/MKBox";
import withSectionAnimation from "styles/animations/components/withSectionAnimation";
import { fadeInVariants } from "styles/animations/sectionFadeIn.animations";
import { fadeInReverseVariants } from "styles/animations/sectionFadeIn.animations";

const AnimatedStyledSectionContainerOne = withSectionAnimation(StyledSectionContainer, fadeInReverseVariants);
const AnimatedStyledSectionContainerTwo = withSectionAnimation(StyledSectionContainer, fadeInVariants);

const HighlightedListItem = ({ text, highlight }) => {
  const { isLg } = useMediaQueries();
  return (
    <ListItem sx={{ display: "flex", alignItems: "center" }}>
      <MKBox
        component="img"
        sx={{ width: "32px", marginRight: ".5rem !important" }}
        src="https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_teal.svg"
        alt="Almadelic butterfly bullet point icon"
      />
      <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: theme.palette.white.main, fontWeight: "bold" }}>
        {text.split(highlight).map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < text.split(highlight).length - 1 && <span style={{ color: theme.palette.accent.main }}>{highlight}</span>}
          </React.Fragment>
        ))}
      </MKTypography>
    </ListItem>
  );
};

const AboutKetamineSectionOne = () => {
  const { isLg, isMdDown, isMd } = useMediaQueries();
  const LeftComponent = () => {
    return (
      <Grid item xs={12} lg={5}>
        <ImageContainer
          image={"https://d3a0rs3velfrz.cloudfront.net/shop-assets/aboutKetamine%232.webp"}
          cssProps={{ maxHeight: isLg ? "520px" : "400px", objectFit: "cover" }}
        />
      </Grid>
    );
  };
  const RightComponent = () => {
    return (
      <Grid item xs={12} lg={6}>
        <HeaderContainer
          title="Why Choose Psychedelic Therapy With Almadelic"
          highlightText={["Psychedelic Therapy"]}
          textColor={theme.palette.white.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          cssProps={{ textAlign: { xs: "left" }, margin: "0rem 0rem 1rem 0rem !important" }}
        />
        <MKTypography
          component={"p"}
          variant={isLg ? "body1" : "body2"}
          sx={{ color: `${theme.palette.white.main} !important`, marginBottom: "1rem" }}
        >
          We understand that the path to mental wellness is unique for each individual. Traditional treatments don't always provide the relief and
          breakthroughs needed to overcome the challenges of depression, anxiety, and other mental health conditions. This is where
          psychedelic-assisted therapy such as KPT (Ketamine Psychedelic Therapy) can make a profound difference.
        </MKTypography>
        <List sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
          <HighlightedListItem text="Exceptional Care, Your Home, Our Guidance" highlight="Your Home," />
          <HighlightedListItem text="Your Safety, Our Priority" highlight="Your Safety," />
          <HighlightedListItem text="Real Relief Now" highlight="Relief Now" />
        </List>

        <StyledButtonContainer maxWidth={isMd ? 300 : "100%"}>
          <MKButton fullWidth component={Link} to="/candidate" color="secondary" variant="contained" size="large">
            Am I A Candidate?
          </MKButton>
        </StyledButtonContainer>
      </Grid>
    );
  };
  return (
    <TwoColumnSection
      columnSpacing={0}
      rowSpacing={0}
      columnGap={6}
      rowGap={4}
      leftComponent={<LeftComponent />}
      rightComponent={<RightComponent />}
    />
  );
};
const AboutKetamineSectionTwo = () => {
  const { isLg, isMd } = useMediaQueries();

  const LeftComponent = () => {
    return (
      <Grid item xs={12} lg={5} order={isLg ? 1 : 0}>
        <ImageContainer
          image={"https://almadelic-assets.s3.us-east-2.amazonaws.com/home/services/at-home.webp"}
          alt="about ketamine"
          cssProps={{ maxHeight: "375px", objectFit: "cover", boxShadow: "0px 4px 12px rgba(0, 0, 0, .25)" }}
        />
      </Grid>
    );
  };
  const RightComponent = () => {
    const { isLg, isMd } = useMediaQueries();

    return (
      <Grid item xs={12} lg={6} order={isLg ? 0 : 1}>
        <HeaderContainer
          title="At-Home Ketamine Therapy"
          highlightText={["At-Home"]}
          textColor={theme.palette.white.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          cssProps={{ textAlign: { xs: "left" }, margin: "0rem 0rem 1rem 0rem !important" }}
        />
        <MKTypography
          component={"p"}
          variant={isLg ? "body1" : "body2"}
          sx={{ color: `${theme.palette.white.main} !important`, marginBottom: "1rem" }}
        >
          Designed to support individuals struggling with depression, anxiety, PTSD, and other mental health challenges, our at-home treatment program
          at Almadelic offers personalized care in the comfort and safety of your own home. Our licensed clinicians conduct thorough assessments, and
          our experienced guides lead every step, ensuring a compassionate and effective pathway to emotional well-being. Discover the transformative
          benefits of at-home ketamine therapy and take a significant step towards lasting relief.
        </MKTypography>
        <StyledButtonContainer maxWidth={isMd ? 300 : "100%"}>
          <MKButton fullWidth component={Link} to="/ketamine-therapy/packages" color="secondary" variant="contained" size="large">
            See Our Packages
          </MKButton>
        </StyledButtonContainer>
      </Grid>
    );
  };
  return (
    <TwoColumnSection
      columnSpacing={0}
      rowSpacing={0}
      columnGap={6}
      rowGap={4}
      leftComponent={<LeftComponent />}
      rightComponent={<RightComponent />}
    />
  );
};
const AboutKetamineSectionThree = () => {
  const { isLg, isMd } = useMediaQueries();
  const LeftComponent = () => {
    return (
      <Grid item xs={12} lg={5}>
        <ImageContainer
          image={
            "https://almadelic-assets.s3.us-east-2.amazonaws.com/psilocybin-therapy/dried-hallucinogenic-magic-mushrooms-2023-11-27-05-17-55-utc.webp"
          }
          alt="about ketamine"
          cssProps={{ height: "375px", objectFit: "cover", boxShadow: "0px 4px 12px rgba(0, 0, 0, .25)" }}
        />
      </Grid>
    );
  };
  const RightComponent = () => {
    const { isLg, isMd } = useMediaQueries();

    return (
      <Grid item xs={12} lg={6}>
        <HeaderContainer
          title="Psilocybin-Assisted Therapy"
          highlightText={["Psilocybin-"]}
          textColor={theme.palette.white.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          cssProps={{ textAlign: { xs: "left" }, margin: "0rem 0rem 1rem 0rem !important" }}
        />
        <MKTypography
          component={"p"}
          variant={isLg ? "body1" : "body2"}
          sx={{ color: `${theme.palette.white.main} !important`, marginBottom: "1rem" }}
        >
          Experience a new frontier in Natural Medicine with regulated psilocybin treatments in Colorado. Our licensed professionals are dedicated to
          guiding you through every step - from preparation to integration. We'll coordinate your visit and work closely with a licensed Healing
          Center to ensure a safe, seamless, and transformative treatment experience. Discover more about revolutionary Psilocybin Therapy and join
          our waiting list today.
        </MKTypography>
        <StyledButtonContainer maxWidth={isMd ? 300 : "100%"}>
          <MKButton fullWidth component={Link} to="/psilocybin-therapy" color="secondary" variant="contained" size="large">
            About Psilocybin Therapy
          </MKButton>
        </StyledButtonContainer>
      </Grid>
    );
  };
  return (
    <TwoColumnSection
      columnSpacing={0}
      rowSpacing={0}
      columnGap={6}
      rowGap={4}
      leftComponent={<LeftComponent />}
      rightComponent={<RightComponent />}
    />
  );
};
const AboutKetamineSectionFour = () => {
  const { isLg, isMd } = useMediaQueries();
  const LeftComponent = () => {
    return (
      <Grid item xs={12} lg={5} order={isLg ? 1 : 0}>
        <ImageContainer
          image={"https://almadelic-assets.s3.us-east-2.amazonaws.com/home/services/directory-home-page.webp"}
          alt="about ketamine"
          cssProps={{ height: "375px", objectFit: "cover", boxShadow: "0px 4px 12px rgba(0, 0, 0, .25)" }}
        />
      </Grid>
    );
  };
  const RightComponent = () => {
    return (
      <Grid item xs={12} lg={6} order={isLg ? 0 : 1}>
        <HeaderContainer
          title="Network of Traditional Therapy Providers"
          highlightText={["Network"]}
          textColor={theme.palette.white.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          cssProps={{ textAlign: { xs: "left" }, margin: "0rem 0rem 1rem 0rem !important" }}
        />
        <MKTypography
          component={"p"}
          variant={isLg ? "body1" : "body2"}
          sx={{ color: `${theme.palette.white.main} !important`, marginBottom: "1rem" }}
        >
          At Almadelic, we are committed to exploring and harnessing all available avenues to enhance mental health care, combining traditional
          therapies and medications, such as SSRIs, with pioneering approaches. We are thrilled about the expanding range of treatment possibilities
          now accessible. Our platform links prescribers, therapists, facilitators, and patients, and is engineered to optimize treatment choices,
          reduce expenses, and efficiently leverage insurance benefits.
        </MKTypography>
        <StyledButtonContainer maxWidth={isMd ? 300 : "100%"}>
          <MKButton fullWidth component={Link} to="/partner-providers" color="secondary" variant="contained" size="large">
            Find A Therapist
          </MKButton>
        </StyledButtonContainer>
      </Grid>
    );
  };
  return (
    <TwoColumnSection
      columnSpacing={0}
      rowSpacing={0}
      columnGap={6}
      rowGap={4}
      leftComponent={<LeftComponent />}
      rightComponent={<RightComponent />}
    />
  );
};
const AboutKetamineSectionFive = () => {
  const { isLg, isMdDown } = useMediaQueries();
  const LeftComponent = () => {
    return (
      <Grid item xs={12} lg={5}>
        <ImageContainer
          image={"https://d3a0rs3velfrz.cloudfront.net/shop-assets/aboutKetamine%231.webp"}
          alt="about ketamine"
          cssProps={{ maxHeight: isLg ? "450px" : "400px", objectFit: "cover", boxShadow: "0px 4px 12px rgba(0, 0, 0, .25)" }}
        />
      </Grid>
    );
  };
  const RightComponent = () => {
    return (
      <Grid item xs={12} lg={6}>
        <HeaderContainer
          title="Explore Our Services"
          highlightText={["Our Services"]}
          textColor={theme.palette.white.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          cssProps={{ textAlign: { xs: "left" }, margin: "0rem 0rem 1rem 0rem !important" }}
        />
        <MKTypography
          component={"p"}
          variant={isLg ? "body1" : "body2"}
          sx={{ color: `${theme.palette.white.main} !important`, marginBottom: "1rem" }}
        >
          Whether you're seeking relief from depression, anxiety, or a desire for personal growth, our team is here to guide you every step of the
          way.
        </MKTypography>
        <ListComponent bulletPoint={true} color={theme.palette.white.main} listItems={ourServicesBulletPointData} />
        <StyledButtonContainer sx={{ display: "flex", gap: "1rem", flexDirection: { xs: "column", sm: "row", md: "row", lg: "column", xl: "row" } }}>
          <MKButton sx={{ paddingInline: "1.25rem" }} component={Link} to="/ketamine-therapy" color="secondary" variant="contained" size="large">
            More About Ketamine Therapy
          </MKButton>
          {/* <MKButton sx={{ paddingInline: "1.25rem" }} component={Link} to="/psilocybin-therapy" color="secondary" variant="outlined" size="large">
            About Psilocybin Therapy
          </MKButton> */}
        </StyledButtonContainer>
      </Grid>
    );
  };
  return (
    <TwoColumnSection
      columnSpacing={0}
      rowSpacing={0}
      columnGap={6}
      rowGap={4}
      leftComponent={<LeftComponent />}
      rightComponent={<RightComponent />}
    />
  );
};

const AboutKetamine = () => {
  return (
    <StyledSectionContainerColorWrapper>
      {/* 1 */}
      <AnimatedStyledSectionContainerOne>
        <AboutKetamineSectionOne />
      </AnimatedStyledSectionContainerOne>
      <CBDivider color={theme.palette.accent.main} />
      {/* 2 */}
      <AnimatedStyledSectionContainerTwo>
        <AboutKetamineSectionTwo />
      </AnimatedStyledSectionContainerTwo>
      <CBDivider color={theme.palette.accent.main} />
      {/* 3 */}
      <AnimatedStyledSectionContainerTwo>
        <AboutKetamineSectionThree />
      </AnimatedStyledSectionContainerTwo>
      <CBDivider color={theme.palette.accent.main} />
      {/* 4 */}
      <AnimatedStyledSectionContainerTwo>
        <AboutKetamineSectionFour />
      </AnimatedStyledSectionContainerTwo>
      <CBDivider color={theme.palette.accent.main} />
      {/* 5 */}
      <AnimatedStyledSectionContainerTwo>
        <AboutKetamineSectionFive />
      </AnimatedStyledSectionContainerTwo>
    </StyledSectionContainerColorWrapper>
  );
};

export default AboutKetamine;
