import React from "react";
// Components
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { List, Grid } from "@mui/material";
import Form from "../Form";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
// Styled Components
import { TwoColumnSection } from "styles/components/twoColumnSection";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import { useTheme } from "@mui/material/styles";
import { StyledSectionContainer } from "styles/components";

const mock = [
  {
    label: "Email",
    value: "Support@almadelic.com",
    icon: (
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
      </svg>
    ),
  },
  {
    label: "Address",
    value: "1385 S. Colorado Blvd. Ste A712  Denver, CO 80222",
    icon: (
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
      </svg>
    ),
  },
  {
    label: "Phone",
    value: "+1 720-741-7205",
    icon: (
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      </svg>
    ),
  },
];

const Contact = () => {
  const theme = useTheme();
  const { isLg } = useMediaQueries();

  // Define a style object for the link
  const linkStyle = {
    textDecoration: "underline !important",
    color: theme.palette.secondary.main,
  };
  const labelStyle = {
    margin: 0,
    fontFamily: "Nunito Sans,Helvetica,Arial,sans-serif",
    fontSize: "1rem",
    fontWeight: "300",
    lineHeight: "1.6",
  };

  const getAriaLabel = (label, value) =>
    label === "Email"
      ? "Send an email to support at almadelic dot com"
      : label === "Phone"
      ? `Call our phone number ${value}`
      : "View our location on Google Maps";

  const getHref = (label, value) =>
    label === "Email"
      ? "mailto:support@almadelic.com"
      : label === "Phone"
      ? `tel:${value}`
      : "https://maps.google.com/maps?q=1385+S.+Colorado+Blvd.+Ste+A712+Denver%2C+CO+80222";

  const LeftComponent = () => (
    <Grid item xs={12} lg={5}>
      <MKTypography variant={"h2"} component={"h2"} gutterBottom>
        Contact <span style={{ color: theme.palette.accent.main }}>Details</span>
      </MKTypography>
      <MKTypography variant={isLg ? "body1" : "body2"} component={"p"} gutterBottom>
        Have a question or want to schedule a consultation? Please use give us a call, email or use the form and we'll get back to you as soon as
        possible.
      </MKTypography>
      <Box component={List} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
        {mock.map((item, i) => (
          <Box key={i} component={ListItem} disableGutters width={"auto"} padding={0}>
            <Box component={ListItemAvatar} minWidth={"auto !important"} marginRight={2}>
              <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                {item.icon}
              </Box>
            </Box>
            <MKBox sx={{ display: "flex", flexDirection: "column" }}>
              <MKTypography variant={isLg ? "body1" : "body2"} component={"p"} sx={{ fontWeight: "bolder" }}>
                {item.label}
              </MKTypography>
              <MKTypography
                variant={isLg ? "body1" : "body2"}
                component={"p"}
                sx={{
                  ...labelStyle,
                }}
              >
                <a
                  aria-label={getAriaLabel(item.label, item.value)}
                  style={linkStyle}
                  href={getHref(item.label, item.value)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.value}
                </a>
              </MKTypography>
            </MKBox>
          </Box>
        ))}
      </Box>
    </Grid>
  );

  const RightComponent = () => (
    <Grid item xs={12} lg={6}>
      <Form />
    </Grid>
  );

  return (
    <StyledSectionContainer component={"section"} id={"contact-us-contact-section"}>
      <TwoColumnSection
        rowSpacing={0}
        columnSpacing={0}
        rowGap={6}
        columnGap={6}
        leftComponent={<LeftComponent />}
        rightComponent={<RightComponent />}
      />
    </StyledSectionContainer>
  );
};

export default Contact;
