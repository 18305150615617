import React from "react";
// Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
// Theme
import { useTheme } from "@mui/material/styles";
// Styled Components
// import { StyledSectionContainer } from "styles/components";
import { TwoColumnSection } from "styles/components/twoColumnSection";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { ImageContainer } from "styles/components/imageContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";

const Hero = () => {
  const theme = useTheme();
  const { isLg } = useMediaQueries();

  const LeftComponent = () => (
    <Grid item alignItems={"center"} xs={12} lg={6} order={isLg ? 0 : 1}>
      <Box>
        <MKTypography variant="h1" component="h1" gutterBottom>
          Contact <span style={{ color: theme.palette.accent.main }}>Us</span>
        </MKTypography>
      </Box>
      <Box>
        <MKTypography variant={isLg ? "body1" : "body2"} component="p">
          We are here to answer your questions and provide you with the support and information you need. Whether you're interested in our ketamine
          therapy services or have general inquiries, we are just a message away. Feel free to reach out to us!
        </MKTypography>
      </Box>
    </Grid>
  );

  const RightComponent = () => (
    <Grid item xs={12} lg={6}>
      <ImageContainer
        image="https://almadelic-assets.s3.us-east-2.amazonaws.com/contactUs/contact_us_new.webp"
        alt="Person on their laptop contacting us."
      />
    </Grid>
  );

  return (
    <StyledSectionContainer component={"section"} id={"contact-us-hero-section"}>
      <TwoColumnSection
        rowSpacing={isLg ? 0 : 6}
        columnSpacing={isLg ? 6 : 0}
        leftComponent={<LeftComponent />}
        rightComponent={<RightComponent />}
      />
    </StyledSectionContainer>
  );
};

export default Hero;
