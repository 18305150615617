import React from "react";
// Styled Components
import { StyledHowToCardsContainer, StyledHowToSectionContainer } from "./index.styles";
import { StyledHowToCard } from "index.styles";
import { StyledImage } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
// Data
import howToData from "./howTo.data.json";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import HeaderContainer from "styles/components/headerContainer";
const HowTo = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledHowToSectionContainer component="section" id="how-to-section">
      <StyledImage
        howto={true}
        height="300"
        width="400"
        src={"https://almadelic-assets.s3.us-east-2.amazonaws.com/preparation/1+single+troche.webp"}
        alt="Orange Ketamine Troche In Its Packaging"
      ></StyledImage>
      <HeaderContainer
        textColor={theme.palette.primary.main}
        highlightColor={theme.palette.accent.main}
        component="h2"
        variant="h2"
        title="How To Self Administer A Ketamine Troche"
        highlightText="Ketamine Troche"
        cssProps={{ marginBottom: "2rem", textAlign: "center" }}
      />
      <StyledHowToCardsContainer>
        {howToData.map(({ instruction }, index) => {
          return (
            <StyledHowToCard key={index}>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                {instruction}
              </MKTypography>
            </StyledHowToCard>
          );
        })}
      </StyledHowToCardsContainer>
    </StyledHowToSectionContainer>
  );
};

export default HowTo;
