import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const FourSquareGridContainer = styled(Grid)(({theme, isLg, imageURL}) => ({
    position: "relative",
    flexDirection: "row",
    justifyContent: "center",
    ...(isLg ? {
        '&::before': {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${imageURL})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "20%",
            zIndex: 0,
        }
    } : {}),
}));
