// Major
import React from "react";
import { Container, Grid } from "@mui/material";

// Components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import HeaderContainer from "styles/components/headerContainer";

// Styles
import theme from "assets/theme";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { StyledGridContainer, StyledItemBox } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { BorderBottom } from "@mui/icons-material";
import { transform } from "framer-motion";

const MutualBenefits = ({ ...props }) => {
  const { title, cards } = props;
  const { isLg, isMd } = useMediaQueries();

  const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  //   const cardsz = [
  //     {
  //       title: "How Ketamine Assist's Mental Health",
  //       descriptionCard: ["Rapid Symtom Relief", "Treatment-Resistant Depression", "Neuroplasticity Enhancement"],
  //     },
  //     {
  //       title: "The Mutual Benefits",
  //       descriptionCard: [
  //         "Faster Symtom Relief with Long-Term Coping Skills",
  //         "Address Both Symptoms and Root Causes",
  //         "Personalized Care with Emotional Support",
  //       ],
  //     },
  //     {
  //       title: "How Traditional Therapy Assist's In Mental Health",
  //       descriptionCard: ["Long-Term Skill Building", "Addressing Root Causes", "Emotional Support & Guidance"],
  //     },
  //   ];

  // const Sections = ({ card, index }) => {
  //   return (
  //     <Container
  //       sx={{
  //         borderRight: index === 1 ? "1px solid white" : null,
  //         borderLeft: index === 1 ? "1px solid white" : null,
  //       }}
  //     >
  //       <MKBox>
  //         <MKTypography variant="h4" element="h4" align="center" sx={{ color: `${theme.palette.white.main} !important` }}>
  //           {card.title}
  //         </MKTypography>
  //       </MKBox>
  //       {card.descriptionCard.map((description, index) => {
  //         return <IndividualItem key={index} description={description} orange={false} />;
  //       })}
  //     </Container>
  //   );
  // };

  const Sections = ({ card, index }) => {
    return (
      <Container
        sx={{
          borderRight: index === 1 && isLg ? `2px solid ${theme.palette.primary.main}` : null,
          borderLeft: index === 1 && isLg ? `2px solid ${theme.palette.primary.main}` : null,
          padding: "0rem !important",
        }}
      >
        <MKBox>
          <MKTypography variant="h3" component="h4" align="center" sx={{ color: `${theme.palette.accent.main} !important`, borderBottom: `2px solid ${theme.palette.primary.main}`, borderTop: !isLg && index !==0 ? `2px solid ${theme.palette.primary.main}` : "none" ,minHeight: "7rem", justifyContent: "center", display: "flex", alignItems: "center", padding: "1rem", backgroundColor: `${theme.palette.grey[300]}` }}>
            {card.title}
          </MKTypography>
        </MKBox>
        {card.descriptionCard.map((description, index) => {
          return (
            <MKTypography variant="h6" component="p" align="center" sx={{ color: `${theme.palette.primary.main} !important`, minHeight: "5rem", justifyContent: "center", display: "flex", alignItems: "center", padding: "1rem" }}>
              {description}
            </MKTypography>
          )
        })}
      </Container>
    );
  };

  const generateRBGColor = (hexColor) => {
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b}, 1.0)`;
  };

  const IndividualItem = ({ description }) => {
    return (
      <StyledItemBox
        sx={{
          backgroundColor: `${generateRBGColor(theme.palette.primary.main)}`,
        }}
      >
        <MKTypography
          variant="body1"
          component="h3"
          align="center"
          sx={{
            color: `${theme.palette.white.main} !important`,
          }}
        >
          {description}
        </MKTypography>
      </StyledItemBox>
    );
  };

  return (
    <>
      <StyledSectionContainer id="ketamine-therapy-hub-mutual-benefits" sx={{ 
          position: "relative",
         }}>
        <HeaderContainer
          component="h2"
          variant="h1"
          cssProps={{ textAlign: "center", marginTop: "0rem !important" }}
          title={title}
          textColor={theme.palette.white.main}
          highlightText={["ketamine", "traditional therapy"]}
          highlightColor={theme.palette.accent.main}
        />
        <StyledGridContainer
          container
          sx={{
            display: isLg ? "flex" : "block",
            border: `3px solid ${theme.palette.primary.main}`,
            borderRadius: "1rem",
            position: "relative",
            zIndex: 1,
            backgroundColor: theme.palette.white.main,
            boxShadow: `0px 0px 10px 5px ${hexToRgb(theme.palette.primary.main)}`,
          }}
        >
          {cards.map((card, index) => {
            return (
              <Grid item container xs={12} sm={12} md={12} lg={4}>
                <Sections card={card} index={index} />
              </Grid>
            );
          })}
        </StyledGridContainer>
        <MKBox
          component="img"
          src="https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/background+watermark+(500+x+200+px).webp"
          sx={{
            position: "absolute",
            bottom: 0,
            left: isLg ? -300 : isMd ? -200 : -100,
            zIndex: 0,
            pointerEvents: "none",
            transform: "scaleX(-1)",
          }}
        />
      </StyledSectionContainer>
    </>
  );
};

export default MutualBenefits;
