import React from "react";
import { Grid, Container, Stack } from "@mui/material";

// Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import ThreeStackCard from "styles/components/threeStackCard";
import { ImageContainer } from "styles/components/imageContainer";

// Styles
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { Link } from "react-router-dom";
import Banner from "components/Banner/banner";
import NumberIcon from "components/NumberIcon/numberIcon";

const threeStackCardV2Styles = {
  position: "relative",
};
const StartYourJourney = ({ ...props }) => {
  const { cards, cta, cardType } = props;
  const ButtonContainer = () => {
    const { text, link } = cta;
    return (
      <MKBox sx={{ display: "flex" }}>
        <MKButton color="secondary" sx={{ padding: "0rem 2rem", margin: "2rem auto 0rem" }} component={Link} to={link}>
          {text}
        </MKButton>
      </MKBox>
    );
  };

  const InfoCards = () => {
    const topComponent = (item) => {
      return (
        <Container sx={{ padding: "0rem !important", margin: "0rem !important" }}>
          <ImageContainer image={item.imageUrl} alt={item.alt} />
        </Container>
      );
    };

    const middleComponent = (item, index) => {
      return (
        <MKBox sx={{ margin: "0.5rem" }}>
          {cardType === "threeStackCard-v2" ? (
            <Stack direction="row" alignItems="center" mt={2}>
              <MKTypography variant="h6" element="h3">
                <NumberIcon
                  customIconSize={45}
                  number={index + 1}
                  dark={true}
                  sx={{ marginRight: "0rem !important", position: "absolute", top: "8px", left: "8px" }}
                />
              </MKTypography>
              <MKTypography variant="h6" element="h3">
                <>{item.description}</>
              </MKTypography>
            </Stack>
          ) : (
            <>
              <MKTypography variant="h4" element="h4" sx={{ fontSize: "4rem", textAlign: "center" }}>
                <>{index + 1}</>
              </MKTypography>
            </>
          )}
        </MKBox>
      );
    };

    const bottomComponent = (item) => {
      return (
        <MKBox sx={{ margin: "0.5rem" }}>
          <MKTypography
            variant={cardType === "threeStackCard-v2" ? "p" : "h4"}
            element={cardType === "threeStackCard-v2" ? "p" : "h4"}
            sx={{ textAlign: "center" }}
          >
            {cardType === "threeStackCard-v2" ? item.paragraph : item.description}
          </MKTypography>
        </MKBox>
      );
    };

    return (
      <Grid
        container
        gap={4}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {cards.map((card, index) => (
          <Grid item xs={12} sm={7} md={5} lg={3} key={index} sx={{ minWidth: "250px !important", maxWidth: "350px !important" }}>
            <ThreeStackCard
              cssProps={cardType === "threeStackCard-v2" ? { ...threeStackCardV2Styles } : {}}
              topComponent={topComponent(card)}
              midComponent={middleComponent(card, index)}
              botComponent={bottomComponent(card)}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Banner content={props} />
      <StyledSectionContainer id="ketamin-therapist-hub-start-your-journey-cards-container">
        <InfoCards />
        <ButtonContainer />
      </StyledSectionContainer>
    </>
  );
};

export default StartYourJourney;
