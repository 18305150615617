import React, { useState } from "react";
import theme from "assets/theme";
import { motion, useAnimation } from "framer-motion";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// Styled Components
import { StyledTestimonialsHeaderBox, StyledTestimonialsSectionContainer, StyledTestimonialsTextBox } from "./index.styles";
import { StyledPaginationDots } from "index.styles";
import { StyledButtonContainer } from "index.styles";
import HeaderContainer from "styles/components/headerContainer";
// Components
import { Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import mockReviewData from "./mockReviewData.json";
import withSectionAnimation from "styles/animations/components/withSectionAnimation";
import { fadeInReverseVariants } from "styles/animations/sectionFadeIn.animations";

const AnimatedStyledTesimonialsSectionContainer = withSectionAnimation(StyledTestimonialsSectionContainer, fadeInReverseVariants);

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const { isMd, isLg } = useMediaQueries();
  const controls = useAnimation();

  const getCardData = (offset) => {
    const index = (currentIndex + offset + mockReviewData.length) % mockReviewData.length;
    return mockReviewData[index] || mockReviewData[0];
  };

  const handlePrev = async () => {
    await controls.start({ scale: 0.8 });
    await controls.start({ x: 50, opacity: 0 });
    setCurrentIndex((prevIndex) => (prevIndex - 1 + mockReviewData.length) % mockReviewData.length);
    controls.set({ x: -50 });
    await controls.start({ x: 0, opacity: 1, scale: 1 });
  };

  const handleNext = async () => {
    await controls.start({ scale: 0.8 });
    await controls.start({ x: -50, opacity: 0 });
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mockReviewData.length);
    controls.set({ x: 50 });
    await controls.start({ x: 0, opacity: 1, scale: 1 });
  };

  return (
    <AnimatedStyledTesimonialsSectionContainer component="section" id="testimonials-section">
      <StyledTestimonialsHeaderBox>
        <HeaderContainer
          title="Real People, Real Results"
          highlightText={["Real Results"]}
          textColor={theme.palette.primary.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          cssProps={{
            margin: "0rem 0rem 1rem 0rem !important",
            "&::before": {
              content: '""',
              display: "block",
              backgroundImage: "url('https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/almadelicButterFlyTransparent.webp')",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "145px",
              height: "105px",
              margin: "auto",
            },
          }}
        />
        <MKTypography sx={{ fontWeight: "300", color: "#000 !important", fontSize: "90%" }} variant="p">
          <em>
            Transforming Lives Through the Power of Ketamine Psychedelic Therapy.
            {isMd && <br />} Hear from Our Community
          </em>
        </MKTypography>
      </StyledTestimonialsHeaderBox>

      <Stack
        component={motion.div}
        animate={controls}
        initial={{ opacity: 1, x: 0, scale: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        direction="row"
        sx={{ justifyContent: "center" }}
      >
        {[-1, 0, 1].map((offset) => (
          <StyledTestimonialsTextBox
            key={offset}
            sx={{
              scale: offset === 0 ? 1 : 0.8,
              boxShadow:
                offset === 0
                  ? "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px"
                  : "rgba(0,0,0,0.2) 0px 0px 10px 0px",
              opacity: offset === 0 ? 1 : 0.5,
            }}
          >
            <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
              "{getCardData(offset).review}"
            </MKTypography>
            <MKTypography component="p" variant="h6">
              - {getCardData(offset).name}
            </MKTypography>
          </StyledTestimonialsTextBox>
        ))}
      </Stack>

      <StyledButtonContainer sx={{ justifyContent: "center", display: "flex", gap: 4, mt: 3, alignItems: "center" }}>
        <MKButton size="small" variant="contained" color="secondary" aria-label="Previous Page" onClick={handlePrev} disabled={currentIndex === 0}>
          <ChevronLeftIcon />
        </MKButton>
        <Stack direction="row" spacing={1}>
          <Stack direction="row" spacing={1}>
            {[-1, 0, 1].map((offset) => {
              const index = (currentIndex + offset + mockReviewData.length) % mockReviewData.length;
              return <StyledPaginationDots key={index} currentindex={currentIndex} index={index} />;
            })}
          </Stack>
        </Stack>
        <MKButton
          size="small"
          variant="contained"
          color="secondary"
          aria-label="Next Page"
          onClick={handleNext}
          disabled={currentIndex === mockReviewData.length - 1}
          sx={{ cursor: currentIndex === mockReviewData.length - 1 ? "grab !important" : "pointer" }}
        >
          <ChevronRightIcon />
        </MKButton>
      </StyledButtonContainer>
    </AnimatedStyledTesimonialsSectionContainer>
  );
};

export default Testimonials;
