import React from "react";
import PropTypes from "prop-types";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import HeaderContainer from "styles/components/headerContainer";
import { useTheme } from "@emotion/react";
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import { ImageContainer } from "styles/components/imageContainer";

const Calendar = ({ ...props }) => {
  const theme = useTheme();
  const { iframeSrc, title, paragraph, image } = props;
  return (
    <StyledSectionContainer cssProps={{ paddingBlock: "0 !important" }}>
      <Grid container columnGap={6} rowGap={2}>
        {/* <Grid item xs={5}>
          <ImageContainer image={image.imageUrl} alt={image.alt} />
        </Grid> */}
        <Grid item xs={12}>
          <HeaderContainer title={title} component="h2" variant="h2" textColor={theme.palette.primary.main} />
          <MKTypography variant="body1" color="text.secondary">
            {paragraph}
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <iframe
            src={iframeSrc}
            style={{
              width: "100%",
              height: "780px",
              border: "none",
              overflow: "hidden",
            }}
            title={title}
          ></iframe>
        </Grid>
      </Grid>
    </StyledSectionContainer>
  );
};

Calendar.propTypes = {
  iframeSrc: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Calendar;
