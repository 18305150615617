// Major
import React from "react";
import { Grid } from "@mui/material";

// Components
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import MKTypography from "components/MKTypography";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import MKButton from "components/MKButton";

// Styles
import theme from "assets/theme";
import { Link } from "react-router-dom";
import { StyledButtonContainer } from "index.styles";
import { StyledSectionContainerColorWrapper } from "styles/components/SectionContainer/index.styles";

import withSectionAnimation from "styles/animations/components/withSectionAnimation";
import { fadeInVariants } from "styles/animations/sectionFadeIn.animations";
import useMediaQueries from "utils/mediaQueries.utils";

const AnimatedStyledSectionContainer = withSectionAnimation(StyledSectionContainer, fadeInVariants);

const GetStartedToday = ({ ...props }) => {
  const { leftColumn, rightColumn, colorWrapper, flipBackground } = props;
  const { isLg } = useMediaQueries();
  const rightContent = () => {
    const { title, highlightText, description, cta } = rightColumn;
    const textColor = colorWrapper ? theme.palette.common.white : theme.palette.primary.main;

    return (
      <Grid item xs={12} md={6}>
        <HeaderContainer
          title={title}
          variant="h2"
          component="h3"
          textColor={textColor}
          highlightText={highlightText}
          highlightColor={theme.palette.accent.main}
        />
        <MKTypography variant={isLg ? "body1" : "body2"} fontWeight={400} gutterBottom sx={{ color: textColor }}>
          {description}
        </MKTypography>
        <StyledButtonContainer>
          <MKButton variant="contained" color="secondary" size="large" component={Link} to={cta.link}>
            {cta.text}
          </MKButton>
        </StyledButtonContainer>
        <MKTypography variant="body2" fontWeight={400} gutterBottom sx={{ padding: "1rem 0rem !important", color: theme.palette.accent.main }}>
          (Just takes 2 minutes)
        </MKTypography>
      </Grid>
    );
  };

  const leftContent = () => {
    const { image } = leftColumn;
    return (
      <Grid item xs={12} md={6}>
        <ImageContainer image={image.imageUrl} alt={image.alt} cssProps={{ maxHeight: isLg ? "480px" : "300px", objectFit: "contain" }} />
      </Grid>
    );
  };

  return (
    <>
      {colorWrapper ? (
        <StyledSectionContainerColorWrapper flipBackground={flipBackground}>
          <AnimatedStyledSectionContainer id="ketamine-hub-get-started">
            <TwoColumnSection leftComponent={leftContent()} rightComponent={rightContent()} />
          </AnimatedStyledSectionContainer>
        </StyledSectionContainerColorWrapper>
      ) : (
        <AnimatedStyledSectionContainer id="ketamine-hub-get-started">
          <TwoColumnSection leftComponent={leftContent()} rightComponent={rightContent()} />
        </AnimatedStyledSectionContainer>
      )}
    </>
  );
};

export default GetStartedToday;
