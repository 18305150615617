import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, Stack } from "@mui/material";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { EllipsisHeaderTypography } from "pages/ScienceAndResearch/components/ClinicalResearch/index.styles";
import CBDivider from "components/CBDivider";
import SEO from "components/CBSEO";
import QuoteSection from "./components/QuoteSection";
import ParagraphSection from "./components/ParagraphSection";
import ImageSection from "./components/ImageSection";
import TitleSection from "./components/TitleSection";
import SubtitleSection from "./components/SubtitleSection";

// styles
import { StyledReadBlogSectionContainer } from "./index.styles";
import ShareIcon from "@mui/icons-material/Share";
import { FacebookShareButton, LinkedinShareButton } from "react-share";

// data
import blogData from "../Blog/components/Result/blogsData.config.json";
import BlogSchema from "pages/Blog/components/blogSchema";

// utils
import { capitalizeFirstLetterOfEachWord } from "utils/helpers/capitalizeFirstLetterOfEachWord";
import useMediaQueries from "utils/mediaQueries.utils";
import { formatTitleForURL } from "utils/helpers/formatTitleForUrl";
import ListComponent from "components/CBList";

const ReadBlog = () => {
  const { isLg } = useMediaQueries();
  const [currentBlog, setCurrentBlog] = useState();
  const location = useLocation();

  const formattedPathname = location.pathname.replace("/blog/", "").replace(/-/g, " ");

  useEffect(() => {
    setCurrentBlog(blogData.find((item) => item.shortTitle.toLowerCase() === formattedPathname.toLowerCase()));
  }, [formattedPathname]);

  const handleShareCopyLink = async () => {
    const urlToCopy = `www.almadelic.com${window.location.pathname + window.location.search}`;

    try {
      await navigator.clipboard.writeText(urlToCopy);
      alert("Copied To Clipboard");
    } catch (err) {
      console.error("Failed to copy URL to clipboard", err);
    }
  };

  return (
    <StyledReadBlogSectionContainer>
      {currentBlog && (
        <>
          <BlogSchema
            title={currentBlog.title}
            authorName={currentBlog.author.name}
            datePublished={currentBlog.date}
            dateModified={currentBlog.date}
            imageUrl={currentBlog.image}
            articleBody={currentBlog.content}
            publisherName="Almadelic Medical Group"
            publisherLogo="https://d3a0rs3velfrz.cloudfront.net/logos/mainLogo.svg"
            breadcrumbs={[
              { name: "Home", url: "https://www.almadelic.com/" },
              { name: "Blog", url: "https://www.almadelic.com/blog" },
              { name: currentBlog.title, url: `https://www.almadelic.com/blog/${formatTitleForURL(currentBlog.shortTitle)}` },
            ]}
          />
          <SEO
            title={capitalizeFirstLetterOfEachWord(currentBlog.shortTitle) + " | Almadelic "}
            description={currentBlog.metaDescription}
            canonicalUrl={`https://www.almadelic.com${location.pathname + location.search}`}
          />
        </>
      )}
      <MKBox
        sx={{
          width: "100%",
          maxHeight: "500px",
          position: "relative",
          display: "inline-block",
          overflow: "hidden",
          marginBlock: "1rem",
          borderRadius: "12px",
        }}
      >
        <MKBox
          component="img"
          sx={{
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "contain",
            borderRadius: "12px",
          }}
          src={currentBlog?.image}
          alt={currentBlog?.title}
        />
        <MKBox
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            boxShadow: "inset 15px 15px 200px #04122b8d",
            pointerEvents: "none",
            borderRadius: "12px",
          }}
        />
      </MKBox>
      <EllipsisHeaderTypography component="h1" variant="h1" textAlign={isLg ? "left" : "left"} gutterBottom>
        {currentBlog?.title}
      </EllipsisHeaderTypography>

      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb="1rem">
        <Stack direction={"row"} spacing={3} justifyContent={isLg ? "left" : "left"} alignItems={"center"}>
          <MKTypography component="p" sx={{ fontSize: "16px" }} variant={isLg ? "body1" : "body2"}>
            By {currentBlog?.author.name}
          </MKTypography>
          <span>|</span>
          <MKTypography component="p" sx={{ fontSize: "16px" }} variant={isLg ? "body1" : "body2"}>
            Posted {currentBlog?.date}
          </MKTypography>
        </Stack>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <FacebookShareButton
            style={{ display: "inline", width: "20px", height: "20px" }}
            url={`www.almadelic.com${location.pathname + location.search}`}
          >
            <img src="https://d3a0rs3velfrz.cloudfront.net/footer/facebook-Icon.webp" alt="Facebook Share Icon" style={{ width: "24px" }} />
          </FacebookShareButton>
          <LinkedinShareButton
            style={{ display: "inline", width: "20px", height: "20px" }}
            url={`www.almadelic.com${location.pathname + location.search}`}
          >
            <img src="https://d3a0rs3velfrz.cloudfront.net/footer/linkedIn-Icon.webp" alt="LinkedIn Share Icon" style={{ width: "24px" }} />
          </LinkedinShareButton>
          <button
            onClick={handleShareCopyLink}
            style={{ background: "transparent", border: "transparent", display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <ShareIcon style={{ width: "24px", height: "24px" }} url={`www.almadelic.com${location.pathname + location.search}`}></ShareIcon>
          </button>
        </Stack>
      </Stack>
      <CBDivider />

      {currentBlog?.content.map((section, index) => {
        switch (section.type) {
          case "quote":
            return <QuoteSection key={index} {...section} />;
          case "paragraph":
            return <ParagraphSection key={index} {...section} />;
          case "image":
            return <ImageSection key={index} {...section} />;
          case "title":
            return <TitleSection key={index} {...section} />;
          case "subtitle":
            return <SubtitleSection key={index} {...section} />;
          case "list":
            return (
              <ListComponent
                cssListProps={{ marginBottom: "1rem" }}
                color={section?.linkColor ? section.linkColor : "#000"}
                key={index}
                {...section}
              />
            );
          default:
            return null;
        }
      })}
      {currentBlog?.resources.length !== 0 && (
        <>
          <CBDivider />
          <MKTypography gutterBottom component="h2" variant="h2">
            <span style={{ color: "#75CDC5" }}>Article</span> Resources
          </MKTypography>
          {currentBlog?.resources.map((resource, index) => {
            return (
              <MKTypography
                key={index}
                sx={{ marginBottom: "1rem", display: "block", maxWidth: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                component={"p"}
                variant={isLg ? "body1" : "body2"}
              >
                <span style={{ color: "#F77C3A" }}>[{index + 1}] </span>
                <Link href={resource}>
                  <span style={{ marginLeft: ".25rem" }}>{resource}</span>
                </Link>
              </MKTypography>
            );
          })}
        </>
      )}
    </StyledReadBlogSectionContainer>
  );
};

export default ReadBlog;
