import theme from "assets/theme";
// MUI
import { Stack } from "@mui/material";
import MKBox from "components/MKBox";
// Components
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Banner = ({ content }) => {
  const { isMd } = useMediaQueries();
  const tealOpaque = hexToRgba(`${theme.palette.accent.main}`, 1);
  const tealTransparent = hexToRgba(`${theme.palette.accent.main}`, 0.1);

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        background: `linear-gradient(to right, ${tealOpaque} 10%, ${tealTransparent} 90%)`,
        padding: "2.5rem 0rem 2.5rem 2rem",
      }}
    >
      {isMd ? (
        <MKBox sx={{ width: "50px" }}>
          <ImageContainer image="https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/white_butterfly.webp" alt="Image of logo" />
        </MKBox>
      ) : null}
      <HeaderContainer
        title={content.title}
        component={"h2"}
        variant={"h2"}
        textColor={theme.palette.white.main}
        cssProps={{ margin: "0 !important" }}
      />
    </Stack>
  );
};
export default Banner;
