import React from "react";
import { useLocation } from "react-router-dom";
// Components
import SEO from "components/CBSEO";
import SchemaComponent from "components/CBSchema";

// Styled Components
import { StyledSectionContainerColorWrapper } from "styles/components/SectionContainer/index.styles";
import { StyledPageContainer } from "styles/components/PageContainer/index.styles";
// Data
import ketamineStateContent from "./data/pageContentData/ketamineStateContent.data.json";
import psilocybinStateContent from "./data/pageContentData/psilocybinStateContent.data.json";
import ketaminStateSchemaData from "./data/schemaData/ketamineStateSchema.data.json";
import psilocybinStateSchemaData from "./data/schemaData/psilocybinStateSchema.data.json";
//components
import StateHeader from "./components/StateHeader";
import LearnMore from "./components/LearnMore";
import WhyChooseKetamine from "./components/WhyChooseKetamine";
import HowAtHomeWorks from "./components/HowAtHomeWorks";
import FindATherapistInState from "./components/FindATherapistInState";
import RealPeopleRealResults from "./components/RealPeopleRealResults";
import CBDivider from "components/CBDivider";
import Page404 from "pages/404";

// Utils
import { capitalizeFirstLetterOfEachWord } from "utils/helpers/capitalizeFirstLetterOfEachWord";
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const therapyStateContentMap = {
  "ketamine-therapy": ketamineStateContent,
  "psilocybin-therapy": psilocybinStateContent,
};
const therapyStateSchemaDataMap = {
  "ketamine-therapy": ketaminStateSchemaData,
  "psilocybin-therapy": psilocybinStateSchemaData,
};
const componentMap = {
  "stateHeader": StateHeader,
  "learnMore": LearnMore,
  "whyChoose": WhyChooseKetamine,
  "howAtHomeWorks": HowAtHomeWorks,
  "findATherapist": FindATherapistInState,
  "realPeaopleRealResults": RealPeopleRealResults,
  "divider": CBDivider,
};

const TherapyState = () => {
  const location = useLocation();
  const modalityType = location.pathname.split("/")[1];

  const groupedComponents = [];
  let backgroundComponents = [];

  const content = therapyStateContentMap[modalityType];
  const contentState = content.find((stateObj) => location.pathname.includes(stateObj.state.toLowerCase()));

  if (contentState.statePage === "not found") return <Page404 />;

  contentState.contentLayout.forEach(({ section }, index) => {
    const Component = componentMap[section?.componentType];
    const props = { ...section };
    const componentElement = <Component key={index} content={props} />;
    if (section.colorWrapper) {
      backgroundComponents.push(componentElement);
    } else {
      if (backgroundComponents.length > 0) {
        groupedComponents.push(<StyledSectionContainerColorWrapper key={`bg-${index}`}>{backgroundComponents}</StyledSectionContainerColorWrapper>);
        backgroundComponents = [];
      }
      groupedComponents.push(componentElement);
    }
  });
  if (backgroundComponents.length > 0) {
    groupedComponents.push(<StyledSectionContainerColorWrapper key="bg-end">{backgroundComponents}</StyledSectionContainerColorWrapper>);
  }

  const capitalizedState = capitalizeFirstLetterOfEachWord(contentState.state);
  const therapyType = modalityType === "ketamine-therapy" ? "Ketamine" : "Psilocybin";
  const dynamicBreadcrumb = { name: `${therapyType} Therapy in ${capitalizedState}`, url: `/${modalityType}/${contentState.state}` };
  const schemaData = constructSchemaData(`https://www.almadelic.com/${modalityType}/${contentState.state.toLowerCase()}`, [
    ...therapyStateSchemaDataMap[modalityType].breadcrumbs,
    dynamicBreadcrumb,
  ]);

  // ! so using .push on the breadcrumbs array is not working. Because push returns the new length of the array, not the array itself. So by spreading the previous breadcrumbs, we add the dynamic breadcrumb to the array and it works as expected.
  // const schemaData = constructSchemaData(
  //   `https://www.almadelic.com/${modalityType}/${contentState.state}`,
  //   therapyStateSchemaDataMap[modalityType].breadcrumbs.push({ name: contentState.state, url: `/${modalityType}/${contentState.state}` })
  // );

  return (
    <StyledPageContainer id={`${modalityType}-therapy-${contentState.state}-page-container`}>
      <SEO
        title={`${therapyType} Therapy in ${capitalizedState} | At-Home & Virtual Treatment`}
        description={`Your source for personalized ${therapyType}-assisted therapy in ${capitalizedState}. Our at-home and virtual treatments are aided by our team of licensed mental health clinicians. Book your ${capitalizedState} ${therapyType} consultation today!`}
        canonicalUrl={`https://www.almadelic.com/${modalityType}/${contentState.state.toLowerCase()}`}
      />
      <SchemaComponent schemas={schemaData} />
      {groupedComponents}
    </StyledPageContainer>
  );
};

export default TherapyState;
