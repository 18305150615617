import { useState } from "react";
import axios from "axios";

const useGhlNewsLetterWebhook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const sendToWebhook = async (data) => {
    try {
      setLoading(true);

      const response = await axios.post(
        // "https://services.leadconnectorhq.com/hooks/pTpZs5Up0T2OAJPqtcLs/webhook-trigger/yMJVu8oluOiVlmZjdJpv",
        "https://services.leadconnectorhq.com/hooks/pTpZs5Up0T2OAJPqtcLs/webhook-trigger/06a14da6-55c8-4327-947d-3c1dc5d9ba72",
        data
      );

      if (response.data.id && response.data.status === "Success: request sent to trigger execution server") {
        setStatus("success");
      } else {
        setStatus("failed");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error sending data to webhook:", error);

      setError(error);
      setStatus("failed");
      setLoading(false);
    }
  };

  return { loading, error, status, sendToWebhook };
};

export default useGhlNewsLetterWebhook;
