import { useState } from "react";
import axios from "axios";

function useVerifyRecaptcha() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const verifyRecaptcha = async (email, recaptchaToken) => {
    try {
      setLoading(true);
      const response = await axios.post("https://62gl3yhjx6.execute-api.us-east-1.amazonaws.com/api-request/verifyRecaptcha", {
        email,
        recaptchaToken,
      });
      if (response.status === 200) {
        setLoading(false);
        setSuccess(true);
        return true;
      } else if (response.status === 400) {
        setLoading(false);
        setError("Email is invalid, please try again...");
        return false;
      }
    } catch (err) {
      setLoading(false);
      setError("An error occurred while verifying the email.");
      console.error("Error verifying email:", err);
      return false;
    }
  };

  return { verifyRecaptcha, error, loading, success, recaptchaToken, onRecaptchaChange };
}

export default useVerifyRecaptcha;
