import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
// Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Box, FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// Hooks
import useGhlContactUsWebhook from "./hook/useGhlContactUsWebhook";
import ReCAPTCHA from "react-google-recaptcha";
import useVerifyRecaptcha from "utils/hooks/useVerifyRecaptcha";
// import useVerifyEmail from "utils/hooks/useVerifyEmail";

const inquiryType = ["New Patient", "Existing Patient", "Partner Therapist", "Career", "Other"];

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid full name")
    .max(50, "Please enter a valid full name")
    .required("Please specify your full name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|mil|info|biz|co|me|io|tech|online|us|uk|ca|au|ac|health|med|doctor|academy|institute|college|law|consulting|clinic|usp\.br)$/,
      "Email must end with valid domain suffixes."
    )
    .required("Email is required"),
  message: yup.string().trim().required("Please specify your message"),
  inquiryType: yup.string().required("Please select an inquiry type"),
});

const Form = () => {
  const theme = useTheme();
  // const { verifyEmail, error: verifyEmailError } = useVerifyEmail();
  const {
    verifyRecaptcha,
    error: verifyRecaptchaError,
    loading: verifyRecaptchaLoading,
    success: verifyRecaptchaSuccess,
    recaptchaToken,
    onRecaptchaChange,
  } = useVerifyRecaptcha();

  const { loading, error, status, sendToWebhook } = useGhlContactUsWebhook();
  const initialValues = {
    fullName: "",
    message: "",
    inquiryType: "",
    email: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    if (!recaptchaToken) {
      alert("Please complete the recaptcha.");
      return;
    }

    try {
      const isRecaptchaValid = await verifyRecaptcha(values.email, recaptchaToken);
      if (isRecaptchaValid) {
        sendToWebhook(values);
        resetForm();
      } else {
        alert("Email is not valid, please try again or contact support.");
      }
    } catch (error) {
      console.error("Recaptcha verification failed:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const areAllFieldsFilled = () => {
    return (
      formik.values.fullName.trim() !== "" &&
      formik.values.email.trim() !== "" &&
      formik.values.inquiryType.trim() !== "" &&
      formik.values.message.trim() !== ""
    );
  };

  return (
    <Box
      margin={"0 auto"}
      component={"form"}
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{
        "& .MuiOutlinedInput-root.MuiInputBase-multiline": {
          padding: 0,
        },
        "& .MuiOutlinedInput-input": {
          color: "black !important",
          background: theme.palette.background.paper,
          padding: 2,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography for="fullName" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
            Full name
          </Typography>
          <TextField
            id="fullName"
            name="fullName"
            disabled={loading || status === "success" || verifyRecaptchaLoading || verifyRecaptchaSuccess === "success"}
            placeholder="Your full name"
            variant="outlined"
            size="medium"
            fullWidth
            type="text"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography for="email" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
            E-mail
          </Typography>
          <TextField
            id="email"
            disabled={loading || status === "success"}
            placeholder="Your e-mail address"
            variant="outlined"
            size="medium"
            name="email"
            fullWidth
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography for="inquiryType" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
            Inquiry Type
          </Typography>
          <FormControl fullWidth error={Boolean(formik.touched.inquiryType) && Boolean(formik.errors.inquiryType)}>
            <Select
              id="inquiryType"
              variant="outlined"
              name="inquiryType"
              value={formik.values.inquiryType}
              onChange={formik.handleChange}
              displayEmpty
              sx={{ height: "52px" }}
            >
              <MenuItem value="" disabled sx={{}}>
                <MKTypography component="p" sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.55)" }}>
                  Select your inquiry type
                </MKTypography>
              </MenuItem>
              {inquiryType.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.inquiryType && <FormHelperText>{formik.errors.inquiryType}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography for="message" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
            Message
          </Typography>
          <TextField
            id="message"
            disabled={loading || status === "success"}
            placeholder="Your question about our services"
            variant="outlined"
            name="message"
            fullWidth
            multiline
            rows={4}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
        </Grid>
        <Grid item xs={12}>
          {areAllFieldsFilled() && <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={onRecaptchaChange} />}
          <MKButton
            aria-label={
              loading || verifyRecaptchaLoading
                ? "Sending..."
                : error || verifyRecaptchaError
                ? "Error..."
                : status || verifyRecaptchaSuccess
                ? "Success!"
                : "Send your question"
            }
            disabled={!recaptchaToken || verifyRecaptchaLoading || verifyRecaptchaSuccess}
            variant="contained"
            type="submit"
            color="secondary"
            size="large"
            sx={{ marginTop: areAllFieldsFilled() ? "2rem" : "1rem" }}
          >
            {loading || verifyRecaptchaLoading
              ? "Sending..."
              : error || verifyRecaptchaError
              ? "Error..."
              : status || verifyRecaptchaSuccess
              ? "Success!"
              : "Send your question"}
          </MKButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Form;
