import React from "react";
// Styled components
import { StyledMusicAndMentalHealthSectionContainer } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledImage } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import HeaderContainer from "styles/components/headerContainer";
const MusicAndMentalHealth = () => {
  const { isLg, isMd } = useMediaQueries();

  const mainTwoSection = () => {
    const leftComponent = () => {
      return (
        <Grid item xs={12} lg={5}>
            <ImageContainer image={"https://d3a0rs3velfrz.cloudfront.net/musicTherapy/musicTherapy-Two.webp"}  />
        </Grid>
      );
    }

    const rightComponent = () => {
      return(
      <Grid  item xs={12} lg={6}>
            
            <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
              The profound influence of music on our thoughts and emotions has long been a subject of examination, particularly concerning its impact
              on mental health.
            </MKTypography>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
              Music therapy, recognized as a popular supportive modality for treating mental illnesses, has garnered attention. Noteworthy studies,
              such as those investigating the effects of music on depression, have yielded positive findings.
            </MKTypography>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
              As reported by the Global Council on Brain Health (GCBH), music exhibits a positive influence on emotional well-being, encompassing mood
              enhancement, anxiety reduction, and stress alleviation.
            </MKTypography>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
              Research into Music Therapy for Depression indicates that incorporating music therapy alongside standard treatment yields short-term
              beneficial effects for individuals with depression. The study suggests that the addition of music therapy to conventional treatment
              surpasses the efficacy of standard treatment alone in alleviating depressive symptoms.
            </MKTypography>
            {!isLg && (
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
                An examination of The Effect of Music on the Human Stress Response exposed participants to a stress-inducing scenario, followed by a
                psychosocial stress test. Divided into groups, participants either listened to relaxing music, the sound of rippling water, or
                experienced no auditory stimulation. Results demonstrated that listening to music has a discernible impact on the human stress
                response, with participants exposed to music showing quicker recovery following a stressor.
              </MKTypography>
            )}
            </Grid>
      )
    }


    return(
      <TwoColumnSection leftComponent={leftComponent()} rightComponent={rightComponent()} columnSpacing={3} rowGap={5} />
    )
   }


  return (
    <StyledSectionBackgroundWrapper id="music-and-mental-health-wrapper">
      <StyledMusicAndMentalHealthSectionContainer component="section" id="music-and-mental-health-section">
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item xs={12}>
            <HeaderContainer
              textColor={theme.palette.white.main}
              highlightColor={theme.palette.accent.main}
              component="h2"
              variant="h2"
              title="The Intersection of \n Music and Mental Health"
              highlightText={["The Intersection of"]}
              cssProps={{
                textAlign: "center",
                borderBottom: `2px solid ${theme.palette.accent.main}`,
                paddingBottom: "1rem",
                height: "90px",
                marginBottom: "2rem",
              }}
            />
          </Grid>
          {mainTwoSection()}

          {isLg && (
            <Grid item xs={12} lg={12}>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
                An examination of The Effect of Music on the Human Stress Response exposed participants to a stress-inducing scenario, followed by a
                psychosocial stress test. Divided into groups, participants either listened to relaxing music, the sound of rippling water, or
                experienced no auditory stimulation. Results demonstrated that listening to music has a discernible impact on the human stress
                response, with participants exposed to music showing quicker recovery following a stressor.
              </MKTypography>
            </Grid>
          )}
        </Grid>
      </StyledMusicAndMentalHealthSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default MusicAndMentalHealth;


// <StyledSectionBackgroundWrapper id="music-and-mental-health-wrapper">
//       <StyledMusicAndMentalHealthSectionContainer component="section" id="music-and-mental-health-section">
//         <Grid container rowSpacing={4} columnSpacing={4}>
//           <Grid item xs={12}>
//             <HeaderContainer
//               textColor={theme.palette.white.main}
//               highlightColor={theme.palette.accent.main}
//               component="h2"
//               variant="h2"
//               title="The Intersection of \n Music and Mental Health"
//               highlightText={["The Intersection of"]}
//               cssProps={{
//                 textAlign: "center",
//                 borderBottom: `2px solid ${theme.palette.accent.main}`,
//                 paddingBottom: "1rem",
//                 height: "90px",
//                 marginBottom: "2rem",
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} lg={6}>
//             {/* <StyledImage
//               height="645"
//               width="530"
//               src={"https://d3a0rs3velfrz.cloudfront.net/musicTherapy/musicTherapy-Two.webp"}
//               sx={{ maxHeight: { sm: "400px", lg: "600px" } }}
//               alt="Image of a lady standing infront of a orange backdrop with here headphones on dancing to here music."
//             /> */}
//             <ImageContainer image={"https://d3a0rs3velfrz.cloudfront.net/musicTherapy/musicTherapy-Two.webp"} />
//           </Grid>
//           <Grid item xs={12} lg={6}>
//             <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
//               The profound influence of music on our thoughts and emotions has long been a subject of examination, particularly concerning its impact
//               on mental health.
//             </MKTypography>
//             <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
//               Music therapy, recognized as a popular supportive modality for treating mental illnesses, has garnered attention. Noteworthy studies,
//               such as those investigating the effects of music on depression, have yielded positive findings.
//             </MKTypography>
//             <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
//               As reported by the Global Council on Brain Health (GCBH), music exhibits a positive influence on emotional well-being, encompassing mood
//               enhancement, anxiety reduction, and stress alleviation.
//             </MKTypography>
//             <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
//               Research into Music Therapy for Depression indicates that incorporating music therapy alongside standard treatment yields short-term
//               beneficial effects for individuals with depression. The study suggests that the addition of music therapy to conventional treatment
//               surpasses the efficacy of standard treatment alone in alleviating depressive symptoms.
//             </MKTypography>
//             {!isLg && (
//               <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
//                 An examination of The Effect of Music on the Human Stress Response exposed participants to a stress-inducing scenario, followed by a
//                 psychosocial stress test. Divided into groups, participants either listened to relaxing music, the sound of rippling water, or
//                 experienced no auditory stimulation. Results demonstrated that listening to music has a discernible impact on the human stress
//                 response, with participants exposed to music showing quicker recovery following a stressor.
//               </MKTypography>
//             )}
//           </Grid>

//           {isLg && (
//             <Grid item xs={12} lg={12}>
//               <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} ` }} gutterBottom>
//                 An examination of The Effect of Music on the Human Stress Response exposed participants to a stress-inducing scenario, followed by a
//                 psychosocial stress test. Divided into groups, participants either listened to relaxing music, the sound of rippling water, or
//                 experienced no auditory stimulation. Results demonstrated that listening to music has a discernible impact on the human stress
//                 response, with participants exposed to music showing quicker recovery following a stressor.
//               </MKTypography>
//             </Grid>
//           )}
//         </Grid>
//       </StyledMusicAndMentalHealthSectionContainer>
//     </StyledSectionBackgroundWrapper>