import React from "react";

import { StyledTwoColumnSection } from "./index.styles";

export const TwoColumnSection = (
  { cssProps, leftComponent, rightComponent, rowSpacing = 0, columnSpacing = 0, direction = "row", rowGap = 0, columnGap = 0 },
  id = ""
) => {
  return (
    <StyledTwoColumnSection
      id={id}
      container
      sx={cssProps}
      rowGap={rowGap}
      columnGap={columnGap}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      direction={direction}
    >
      {leftComponent}
      {rightComponent}
    </StyledTwoColumnSection>
  );
};
