// Major
import React from "react";
import { Grid } from "@mui/material";

// Styles
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import theme from "assets/theme";
import MKBox from "components/MKBox";

// components
import { ImageContainer } from "styles/components/imageContainer";
import HeaderContainer from "styles/components/headerContainer";
import MKTypography from "components/MKTypography";
import FourSquareComponent from "styles/components/fourSquareComponent";
import FourSquareBasicComponent from "styles/components/fourSqaureBasicComponent";
import useMediaQueries from "utils/mediaQueries.utils";
import boxShadow from "assets/theme/functions/boxShadow";
const KeyMechanics = ({ ...props }) => {
  const { title, cards } = props;
  const { isLg, isMd } = useMediaQueries();
  const gridSection = (item) => {
    return (
      // <Grid item xs={12} lg={6} sx={{ padding: "0rem 1rem"}}>
      <>
        <ImageContainer
          image={item.imageUrl}
          alt={item.title}
          cssProps={{
            width: "4.5rem",
            height: "auto",
          }}
        />
        <HeaderContainer title={item.title} component="h3" variant="h3" textColor={theme.palette.primary.main} cssProps={{ textAlign: "center" }} />
        <MKTypography component="p" sx={{ textAlign: "center" }} color="primary">
          {item.description}
        </MKTypography>
        </>
      // </Grid>
    );
  };

  const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <StyledSectionContainer id="ketamin-therapist-hub-key-mechanics">
      {/* <Grid container> */}
        <HeaderContainer
          cssProps={{ textAlign: "center", justifyContent: "center", marginTop: "0rem !important", marginBottom: "2rem" }}
          highlightText={"Works"}
          highlightColor={theme.palette.accent.main}
          title={title}
          component={"h2"}
          variant={"h1"}
          textColor={theme.palette.white.main}
        />
        {/* <FourSquareComponent
          component1={gridSection(cards[0])}
          component2={gridSection(cards[1])}
          component3={gridSection(cards[2])}
          component4={gridSection(cards[3])}
          dividerColor={theme.palette.white.main}
          imageURL={"https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_gold.svg"}
        /> */}
        <FourSquareBasicComponent
          component1={gridSection(cards[0])}
          component2={gridSection(cards[1])}
          component3={gridSection(cards[2])}
          component4={gridSection(cards[3])}
          dividerColor={theme.palette.primary.main}
          imageURL={"https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_gold.svg"}
          cssProps={{
            backgroundColor: theme.palette.white.main,
            boxShadow: `0px 0px 10px 5px ${hexToRgb(theme.palette.primary.main)}`,
            border: `3px solid ${theme.palette.primary.main}`,
          }}
        />
        <MKBox
          component="img"
          src="https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/background+watermark+(500+x+200+px).webp"
          sx={{
            position: "absolute",
            bottom: 0,
            right: isLg ? -300 : isMd ? -200 : -100,
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <MKBox
          component="img"
          src="https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/background+watermark+(500+x+200+px).webp"
          sx={{
            position: "absolute",
            top: 0,
            left: isLg ? -300 : isMd ? -200 : -100,
            zIndex: -1,
            pointerEvents: "none",
            transform: "scaleX(-1)",
          }}
        />
      {/* </Grid> */}
    </StyledSectionContainer>
  );
};

export default KeyMechanics;


// {
//   "section": {
//     "componentType": "MutualBenefits",
//     "title": "Mutual Benefits of Ketamine Therapy",
//     "colorWrapper": true,

//     "cards": [
//       {
//         "title": "How Ketamine Assists in Mental Health",
//         "descriptionCard": ["Rapid Symptom Relief", "Treatment-Resistant Depression", "Neuroplasticity Enhancement"]
//       },
//       {
//         "title": "The Mutual Benefits",
//         "descriptionCard": [
//           "Faster Symptom Relief with Long-Term Coping Skills",
//           "Address Both Symptoms and Root Causes",
//           "Personalized Care with Emotional Support"
//         ]
//       },
//       {
//         "title": "How Traditional Therapy Assists in Mental Health",
//         "descriptionCard": ["Long-Term Skill Building", "Addressing Root Causes", "Emotional Support & Guidance"]
//       }
//     ]
//   }
// },

// {
//   "section": {
//     "componentType": "MutualBenefits",
//     "title": "Benefits of Combining Psilocybin with Traditional Therapy",
//     "colorWrapper": true,
//     "cards": [
//       {
//         "title": "How Psilocybin Assists in Mental Health",
//         "descriptionCard": ["Deep Emotional Release", "Altering Negative Thought Patterns", "Neuroplasticity & Enhanced Mind-Body Connection"]
//       },
//       {
//         "title": "The Mutual Benefits",
//         "descriptionCard": ["Long-Term Skill Building", "Addressing Root Causes", "Emotional Support & Guidance"]
//       },
//       {
//         "title": "How Traditional Therapy Assists in Mental Health",
//         "descriptionCard": [
//           "Faster Symptom Relief with Deeper Emotional Insights",
//           "Rewiring Thought Patterns with Long-Term Coping Strategies",
//           "Holistic Healing with Personalized Emotional Support"
//         ]
//       }
//     ]
//   }
// },
