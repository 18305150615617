import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const BlogSchema = ({
  title,
  authorName,
  datePublished,
  dateModified,
  imageUrl,
  articleBody,
  publisherName,
  publisherLogo,
  breadcrumbs,
  description,
  mainEntityOfPage,
  resources,
}) => {
  //! formattedArticleBody- Used to format the articleBody array of objects into a single string for the schema
  const formattedArticleBody = articleBody
    .map((item) => {
      if (item.type === "paragraph" || item.type === "title") {
        return item.content;
      } else if (item.type === "list") {
        return item.content.join(", ");
      }
      return "";
    })
    .filter((text) => text)
    .join(" ");
  const articleSchema = {
    "@context": "http://schema.org",
    "@type": "Article",
    "headline": title,
    "author": {
      "@type": "Person",
      "name": authorName,
    },
    "datePublished": new Date(datePublished).toISOString(),
    "dateModified": new Date(dateModified).toISOString(),
    "image": imageUrl,
    "articleBody": formattedArticleBody,
    "publisher": {
      "@type": "Organization",
      "name": publisherName,
      "logo": {
        "@type": "ImageObject",
        "url": publisherLogo,
      },
    },
    "description": description,
    "mainEntityOfPage": mainEntityOfPage,
    "wordCount": formattedArticleBody.split(" ").length,
    "about": [
      {
        "@type": "Thing",
        "name": "Mental Health",
      },
    ],
    "citation": resources?.map((url, index) => ({
      "@type": "CreativeWork",
      "name": `Source ${index + 1}`,
      "url": url,
    })),
  };

  const breadcrumbSchema = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbs.map((breadcrumb, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": breadcrumb.name,
      "item": `https://www.almadelic.com${breadcrumb.url}`,
    })),
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(articleSchema)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumbSchema)}</script>
    </Helmet>
  );
};

BlogSchema.propTypes = {
  title: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  datePublished: PropTypes.string.isRequired,
  dateModified: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  articleBody: PropTypes.string.isRequired,
  publisherName: PropTypes.string.isRequired,
  publisherLogo: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  description: PropTypes.string.isRequired,
  mainEntityOfPage: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BlogSchema;
