import React, { useContext, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Grid, Stack } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import rgba from "assets/theme/functions/rgba";
// Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ListComponent from "components/CBList";
import Testimonials from "./components/testimonials";
// Styled Components
import HeaderContainer from "styles/components/headerContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import HorizontalInfoCard from "styles/components/horizontalInfoCard";
import { StyledButtonContainer } from "index.styles";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { CartContext } from "utils/context/cart.context";
import { formatTitleForURL } from "utils/helpers/formatTitleForUrl";
// Animations
import { listVariants, listItemVariants } from "styles/animations/staggeredList.animations";
import { headerContainerTitleVariants2 } from "styles/animations/headerContainer.animations";
import { fadeInVariants } from "styles/animations/sectionFadeIn.animations";
import { defaultProductVariants } from "styles/animations/shop.animations";
import withSectionAnimation from "styles/animations/components/withSectionAnimation";
// import ProductAccordion from "components/Ecom/components/productAccordion";
// import { parseProductDesc } from "utils/helpers/parseIncludesMetadata";

const cardCssProps = {
  alignItems: "space-between",
  justifyContent: "center",
  height: "150px",
};

const getTypographyStyle = (selectedProduct, isSelected, theme) => ({
  ...(selectedProduct !== null &&
    !isSelected && {
      color: `${theme.palette.accent.main} !important`,
    }),
});
const AnimatedStyledSectionContainer = withSectionAnimation(StyledSectionContainer, fadeInVariants);

const ProductSection = ({ setSelectedProduct, selectedProduct, sortedPackages }) => {
  const theme = useTheme();
  const { setIsCartOpen, addItemToCart } = useContext(CartContext);

  const LeftComponent = () => {
    const { isLg } = useMediaQueries();

    const handleProductClick = (product) => {
      setSelectedProduct(product);

      if (!isLg) {
        const rightComponent = document.getElementById("product-details-section");
        if (rightComponent) {
          const yOffset = isLg ? -140 : -90;
          const y = rightComponent.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    };

    const TopCardComponent = ({ metadata, name, isSelected }) => {
      return (
        <Stack direction={"row"} justifyContent={"space-between"} spacing={2} sx={{ width: "100%" }}>
          <Stack direction={"column"} spacing={1}>
            <MKTypography component="h2" variant="h5" sx={getTypographyStyle(selectedProduct, isSelected, theme)}>
              {metadata["forPatientType"] || ""}
            </MKTypography>
            <MKTypography sx={{ color: `${theme.palette.accent.main}!important` }} component="h3" variant="h6">
              {name}
            </MKTypography>
          </Stack>
          <MKTypography
            variant="body2"
            component="p"
            sx={{ color: `${theme.palette.primary.main}!important`, fontWeight: "bold", ...getTypographyStyle(selectedProduct, isSelected, theme) }}
          >
            {metadata["sessionCount"] || "N/A"} Sessions
          </MKTypography>
        </Stack>
      );
    };

    const BottomCardComponent = ({ metadata, isSelected }) => {
      const { isLg, isSmUp } = useMediaQueries();
      return (
        <Stack direction={isSmUp ? "row" : "column"} spacing={isLg ? 0 : 1} justifyContent={"space-between"} alignItems={isSmUp && "center"}>
          <MKTypography
            component="p"
            variant="body2"
            sx={{ color: `${theme.palette.primary.main} !important`, ...getTypographyStyle(selectedProduct, isSelected, theme) }}
          >
            {metadata["priceValue"]}
          </MKTypography>
          <MKButton
            component={Link}
            size="small"
            to={`/ketamine-therapy/packages/${formatTitleForURL(selectedProduct?.name)}`}
            disabled={!isSelected}
            sx={{
              color: theme.palette.secondary.main,
              zIndex: 1000,

              justifyContent: "flex-start",

              textDecoration: "underline",
              lineHeight: "1.5rem",
              paddingInline: 0,
              ...getTypographyStyle(selectedProduct, isSelected, theme),
              "&.Mui-disabled": {
                backgroundColor: `${rgba(0, 0, 0, 0)} !important`,
              },
            }}
          >
            View Package Details <ArrowRightIcon sx={{ height: "1.5rem", width: "1.5rem" }} />
          </MKButton>
        </Stack>
      );
    };

    return (
      <Grid id="product-cards-section" item xs={12} lg={6}>
        <MKBox
          sx={{
            height: { xs: sortedPackages.length > 3 ? "400px" : "100%", md: sortedPackages.length > 3 ? "400px" : "100%" },
            maxHeight: { xs: "400px", md: "400px" },
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: { xs: sortedPackages.length > 3 ? "1rem" : "0" },
            overflowY: sortedPackages.length > 3 ? "auto" : "",
            justifyContent: selectedProduct ? "flex-start" : sortedPackages.length > 3 ? "flex-start" : "center",
          }}
        >
          <AnimatePresence>
            {sortedPackages.map((product) => {
              const { name, id, metadata } = product;
              const isSelected = selectedProduct?.id === id;

              const animateProps = {
                scale: isSelected ? 1 : 0.95,
                filter: isSelected ? "grayscale(0)" : "grayscale(1)",
                boxShadow: isSelected
                  ? `0px 0px 25px ${rgba(theme.palette.accent.main, 0.6)}`
                  : `0px 0px 25px ${rgba(theme.palette.accent.main, 0.3)}`,
              };

              const animatePropsHover = {
                scale: !isSelected ? 0.96 : 1,
                cursor: "pointer",
                transition: { type: "spring", stiffness: 100, damping: 10 },
              };

              return (
                <HorizontalInfoCard
                  key={id}
                  layoutId={`product-${id}`}
                  initial={defaultProductVariants.initial}
                  animate={{
                    ...defaultProductVariants.animate,
                    ...animateProps,
                  }}
                  whileHover={{
                    ...animatePropsHover,
                  }}
                  exit={defaultProductVariants.exit}
                  transition={{ type: "spring", stiffness: 10, damping: 5 }}
                  cssProps={{
                    ...cardCssProps,
                  }}
                  topComponent={<TopCardComponent metadata={metadata} name={name} isSelected={isSelected} />}
                  botComponent={<BottomCardComponent metadata={metadata} isSelected={isSelected} />}
                  handleClick={() => handleProductClick(product)}
                />
              );
            })}
          </AnimatePresence>
        </MKBox>
      </Grid>
    );
  };

  const RightComponent = () => {
    const currentSelectedProduct = selectedProduct || sortedPackages[0];
    const { metadata } = currentSelectedProduct || { metadata: [] };
    const { isMd, isLg } = useMediaQueries();

    const handleAddToCart = () => {
      addItemToCart(currentSelectedProduct);
      setIsCartOpen(true);
    };
    useEffect(() => {
      setSelectedProduct(currentSelectedProduct);
    }, [currentSelectedProduct]);

    return (
      <>
        <Grid id="product-details-section" item xs={12} lg={5} sx={{ height: { lg: "419px" } }}>
          <HeaderContainer
            motionProps={{
              variants: {
                child: headerContainerTitleVariants2,
              },
              animate: "show",
              initial: "hidden",
            }}
            subtitle={"What's Included?"}
            title={metadata.includesTitle}
            textColor={theme.palette.primary.main}
            highlightColor={theme.palette.accent.main}
            component="h2"
            variant="h3"
            cssProps={{
              borderBottom: `2px solid ${theme.palette.accent.main}`,
              paddingBlock: ".5rem !important",
              marginBottom: "1rem !important",
            }}
          />
          {JSON.parse(metadata.product_details_bullet_point_titles) && (
            <ListComponent
              motionProps={{
                variants: {
                  listItem: listItemVariants,
                  list: listVariants,
                },
                animate: "show",
                initial: "hidden",
              }}
              listItems={JSON.parse(metadata.product_details_bullet_point_titles)}
              icon={
                <MKBox
                  component="img"
                  sx={{ width: "24px", marginRight: ".5rem !important" }}
                  src="https://d3a0rs3velfrz.cloudfront.net/logos/almadelicButterFlyTransparent.webp"
                  alt="Almadelic butterfly bullet point icon"
                />
              }
            />
          )}
          {/* <ProductAccordion titles={JSON.parse(metadata.product_details_bullet_point_titles)} descriptions={parseProductDesc(metadata)} /> */}
          <Stack
            component={motion.div}
            initial={{ opacity: 0, y: -20 }} // Start with opacity 0 and slightly below
            animate={{ opacity: 1, y: 0 }} // Animate to full opacity and original position
            transition={{ delay: 0.8, duration: 0.5 }} // Delay and duration for the animation
            direction={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            sx={{
              padding: ".5rem",
              // background: rgba(theme.palette.primary.main, 0.9),
              background: rgba(theme.palette.grey[200], 1),
              borderRadius: "12px",
            }}
            // sx={{ marginTop: "1rem", paddingTop: "1rem ", borderTop: `2px solid ${theme.palette.accent.main}` }}
          >
            <MKTypography component="h3" variant="body2">
              <span style={{ fontWeight: "bold", color: theme.palette.primary.main }}>Total Cost:</span> $
              {((currentSelectedProduct.price / 100) * metadata.duration_months).toFixed(2)}
            </MKTypography>
            <MKTypography component="span" variant="span" sx={{ color: theme.palette.grey[400] }}>
              |
            </MKTypography>
            <MKTypography component="h3" variant="body2">
              <span style={{ fontWeight: "bold", color: theme.palette.primary.main }}>Per Session:</span> $
              {(((currentSelectedProduct.price / 100) * metadata.duration_months) / metadata.sessionCount).toFixed(2)}
            </MKTypography>
          </Stack>
          <StyledButtonContainer>
            <Stack direction={isMd ? "row" : "column"} spacing={2}>
              <MKButton onClick={handleAddToCart} variant="contained" color="secondary">
                Add To Cart
              </MKButton>
            </Stack>
          </StyledButtonContainer>
        </Grid>
      </>
    );
  };

  return (
    <AnimatedStyledSectionContainer id="shop-page-product-section">
      <TwoColumnSection
        cssProps={{
          alignItems: "center !important",
        }}
        rowGap={6}
        columnGap={6}
        leftComponent={
          <>
            <LeftComponent />
            <Grid item xs={12} order={1} style={{ width: "100%" }}>
              <Testimonials />
            </Grid>
          </>
        }
        rightComponent={
          <>
            <RightComponent />
          </>
        }
      />
    </AnimatedStyledSectionContainer>
  );
};

export default ProductSection;
