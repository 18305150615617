import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as yup from "yup";
// MUi
import styled from "@emotion/styled";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Box, Grid, TextField } from "@mui/material";
import MKBox from "components/MKBox";
// Components & Styles
import { StyledFooterLink } from "index.styles";
// Theme
import theme from "assets/theme";
// Hooks
import useGhlNewsLetterWebhook from "../../hooks/useGhlNewsLetterWebhook";
import useVerifyRecaptcha from "utils/hooks/useVerifyRecaptcha";

const StyledFooterInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  marginTop: "1rem",
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|mil|info|biz|co|me|io|tech|online|us|uk|ca|au|ac|health|med|doctor|academy|institute|college|law|consulting|clinic|usp\.br)$/,
      "Email must end with valid domain suffixes."
    )
    .required("Email is required"),
});

const initialValues = {
  email: "",
};
const FooterForm = () => {
  const { loading, error, status, sendToWebhook } = useGhlNewsLetterWebhook();
  const {
    verifyRecaptcha,
    error: verifyRecaptchaError,
    loading: verifyRecaptchaLoading,
    success: verifyRecaptchaSuccess,
    recaptchaToken,
    onRecaptchaChange,
  } = useVerifyRecaptcha();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!recaptchaToken) {
        alert("Please complete the recaptcha.");
        return;
      }

      try {
        const isRecaptchaValid = await verifyRecaptcha(values.email, recaptchaToken);
        if (isRecaptchaValid) {
          await sendToWebhook(values);
          resetForm();
        } else {
          alert("Recaptcha verification failed, please try again.");
        }
      } catch (error) {
        console.error("Recaptcha verification failed:", error);
      }
    },
  });

  return (
    <Grid autoComplete="off" item xs={12} xl={5} sx={{ minHeight: "178px" }} component={"form"} onSubmit={formik.handleSubmit} noValidate>
      <MKTypography sx={{ color: theme.palette.white.main }} component="h6" variant="button" fontWeight="bold" textTransform="capitalize" mb={1}>
        Let's stay in touch!
      </MKTypography>
      <MKTypography sx={{ color: theme.palette.white.main }} component="p" variant={"button"} textTransform="capitalize" gutterBottom>
        Learn more about the latest ketamine therapy news <br />
        and special offers for our programs.
      </MKTypography>
      <StyledFooterInputContainer>
        <TextField
          sx={{
            minWidth: "100%",
            marginBottom: formik.values.email ? "1rem" : "0",
          }}
          name="email"
          type="email"
          placeholder="Enter Email Here..."
          value={formik.values.email}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          autoComplete="off"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          helpertextstyle={{ color: "red !important" }}
        />
        <MKButton
          disabled={!recaptchaToken || verifyRecaptchaLoading || verifyRecaptchaSuccess}
          type="submit"
          size="small"
          color="secondary"
          variant={"contained"}
          sx={{
            display: "block",
            borderRadius: "6px",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            maxHeight: "45px",
          }}
        >
          {loading || verifyRecaptchaLoading
            ? "Sending..."
            : error || verifyRecaptchaError
            ? "Error..."
            : status || verifyRecaptchaSuccess
            ? "Success!"
            : "Sign up"}
        </MKButton>
      </StyledFooterInputContainer>
      {formik.values.email && <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={onRecaptchaChange} />}
      {formik.values.email && (
        <MKBox>
          <MKTypography
            sx={{ color: theme.palette.white.main, fontSize: "10px", mt: 1, display: "inline-block", zIndex: 500 }}
            component="p"
            textTransform="capitalize"
            gutterBottom
          >
            By clicking "Sign Up", you agree to receive automated reminders and promotional messages from Almadelic Management Services, LLC. You also
            agree to our <StyledFooterLink to="/terms">Terms Of Service</StyledFooterLink>
            &nbsp; and &nbsp;<StyledFooterLink to="/privacy-policy">Privacy Policy</StyledFooterLink>. This agreement isn't a condition of any
            purchase. Msg & Data rates may apply. Reply STOP to end or HELP for help.
          </MKTypography>
        </MKBox>
      )}
    </Grid>
  );
};

export default FooterForm;
