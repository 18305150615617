import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";
import { motion } from "framer-motion";

export const StyledTestimonialsSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  display: "flex",
  gap: "2rem",
  justifyContent: "center",
  flexDirection: "column",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledTestimonialsTextBox = styled(motion(MKBox))(({ theme }) => ({
  // boxShadow:
  // "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
  borderRadius: "12px",
  padding: "4rem",
  maxWidth: "700px",
  height: "350px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center",
  textAlign: "center",
  gap: ".5rem",
  position: "relative",
  minWidth: "100%",
  borderBottom: `12px solid ${theme.palette.accent.main}`,
  [theme.breakpoints.up("md")]: {
    minWidth: "90%",
  },
  [theme.breakpoints.up("lg")]: {
    minWidth: "80%",
  },
  [theme.breakpoints.up("xl")]: {
    minWidth: "70%",
  },
}));

export const StyledTestimonialsHeaderBox = styled(MKBox)(({ theme }) => ({
  textAlign: "center",
}));
