import { useTheme } from "@emotion/react";
import useMediaQueries from "utils/mediaQueries.utils";

const { StyledIndexIcon } = require("components/CBHowItWorks/index.styles");

const NumberIcon = ({ number, dark, sx, customIconSize }) => {
  const { isMd, isLg } = useMediaQueries();
  const theme = useTheme();
  const iconSize = customIconSize || (isMd || isLg ? 60 : 45); // Adjust size based on screen size

  return (
    <StyledIndexIcon sx={sx}>
      <svg width={iconSize} height={iconSize}>
        <defs>
          <radialGradient id="grad" cx="41%" cy="41%" r="70%" fx="41%" fy="30%">
            <stop offset="0%" style={{ stopColor: dark ? theme.palette.accent.main : "white", stopOpacity: 1 }} />
            <stop offset="70%" style={{ stopColor: dark ? theme.palette.accent.main : "white", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: dark ? "white" : theme.palette.accent.main, stopOpacity: 1 }} />
          </radialGradient>
        </defs>
        <circle cx={iconSize / 2} cy={iconSize / 2} r={iconSize / 2} fill="url(#grad)" />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".3em"
          style={{ fill: dark ? "white" : theme.palette.accent.main, fontSize: "30px", fontWeight: "bold" }}
        >
          {number}
        </text>
      </svg>
    </StyledIndexIcon>
  );
};

export default NumberIcon;
