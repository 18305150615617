import React from "react";
import Hero from "./components/Hero";
import AboutKetamine from "./components/AboutKetamine/newIndex";
import Testimonials from "./components/Testimonials";
import { StyledMainPageContainer } from "index.styles";
import SchemaComponent from "components/CBSchema";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import homeSchemaData from "./schema.data.json";
import SEO from "components/CBSEO";
import seoData from "./seo.data.json";
import GetStartedToday from "pages/TherapyHub/components/GetStartedToday";

const getStartedTodayData = [
  {
    leftColumn: {
      "image": {
        "imageUrl": "https://almadelic-assets.s3.us-east-2.amazonaws.com/home/services/almadelic_logo_large.webp",
        "alt": "Almadelic Logo",
      },
    },
    rightColumn: {
      "title": "Get Started Today With \\n Your Free Consultation",
      "highlightText": ["Your Free Consultation"],
      "description":
        "Whether you're seeking relief from depression, anxiety, or a desire for personal growth, our team is here to guide you every step of the way.",
      "cta": {
        "text": "Schedule A Free Consultation",
        "link": "https://freeconsultation.almadelic.com/get-started-complementary-consultation",
      },
    },
  },
];
const Home = () => {
  const schemaData = constructSchemaData(homeSchemaData.url, homeSchemaData.breadcrumbs);

  return (
    <StyledMainPageContainer id="home-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <Hero />
      <AboutKetamine />
      <Testimonials />
      <GetStartedToday {...getStartedTodayData[0]} colorWrapper={true} flipBackground={true} />
    </StyledMainPageContainer>
  );
};

export default Home;
